.article-detail-first-row{
    width: 100%;
    display: flex;
    flex-direction: row;
}

.article-detail-first-row-cell-1{
    flex:1;
    display: flex;
    flex-direction: column;
}


.article-detail-first-row-cell-2{
    width: 400px;
    display: flex;
    flex-direction: column;
}

.bg-image-squared{
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
}

.enlarge-image-hover{
    background-color: rgba(0,0,0,0.5);
    transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
    opacity: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left:0; top:0;
    right:0; bottom: 0;
    z-index: 5;
}

.enlarge-image-hover:hover{
    opacity: 1;
}

.article-detail-pennant{
    height:30%;
    width: 30%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: absolute;
    left:0;
    top:0;
    z-index: 1;
}

@media (max-width: 768px) {
    .article-detail-first-row{
        flex-direction: column;
    }
    .article-detail-first-row-cell-2{
        margin-top: 20px;
    }
    .bg-image-squared-large{
        width: 280px !important;
        height:280px !important;
    }
    .article-detail-first-row-cell-2{
        width: 100%;
    }
}
