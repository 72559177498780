.article-row-wrapper{
    width: 100%;
    height:100px;
    display:flex;
    flex-direction: row;
    border-bottom: 1px solid #EEEEEE;
    justify-content: flex-start;
    align-items: center;
    cursor:pointer;
    transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
}

.article-row-wrapper:hover{
    background-color: rgba(0,0,0,0.04);
}

.article-image-wrapper{
    width: 100px;
    height: 100px;
    padding:5px;
    border-right: 1px solid #EEEEEE;
}

.article-image{
    height:100%;
    width: 100%;
    border-radius: 4px;
    border:1px solid #EEEEEE;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
}

.article-pennant{
    height:50%;
    width: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: absolute;
    left:0;
    top:0;
    z-index: 1;
}

.article-name-desc{
    height:100%;
    width:300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 5px;
    border-right: 1px solid #EEEEEE;
    overflow: hidden;
}

.article-row-card{
    display: none;
    flex-direction: column;
}

@media (max-width: 968px){
    .article-row-wrapper{
        display: none;
    }

    .article-row-card{
        display: flex;
    }

    .article-wrapper{
        padding: 1px;
    }
}
