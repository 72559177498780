.quotes-card-header{
    display: none;
}

@media (max-width: 968px) {
    .quotes-paper{
        box-shadow: none !important;
        border:none !important;
    }

    .quotes-table-header{
        display: none !important;
    }

    .quotes-card-header{
        display: flex !important;
    }
}
