.app {
    height: 100%;
    width: 100%;

}

.app-header-wrapper{
    width: 100%;
}

.app-content-wrapper{
    min-height:100vh;
    width: 100%;
    padding-top: 25px;
    padding-bottom: 25px;
    background-color: #FAFAFA;
}

.app-footer-wrapper{
    width: 100%;
}

.breadcrumb-link{
    transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
}

.breadcrumb-link:hover{
    text-decoration: underline;
}

.sort-by-icon{
    transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
}

.sort-by-icon-desc{
    transform: rotate(180deg);
}
