.footer-wrapper{
    width: 100%;
    min-height:300px;
    background-color: #414141;
}

.footer-logos-wrapper{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
}

.footer-sitemap-wrapper{
    width: 100%;
    border-top:1px solid #CCCCCC;
    border-bottom:1px solid #CCCCCC;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 20px;
    padding-bottom: 20px;
}

.footer-sitemap-column{
    display: flex;
    flex:1;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 10px;
}

.footer-link{
    text-decoration: none !important;
    transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
    cursor: pointer;
}

.footer-link:hover{
    color: #FFFFFF;
}

.footer-copyright{
    width: 100%;
    text-align: left;
    margin:0;
    padding-top: 20px;
    padding-bottom: 20px;
    color:#CCCCCC;
    font-size: 14px;
}

@media (max-width: 768px){

    .footer-sitemap-wrapper{
        flex-direction: column;
    }

    .footer-sitemap-column{
        margin-bottom: 20px;
    }
}
