
.general-information-table{
    width: 400px;
    max-width: 100%;
}

.quote-row-wrapper{
    width: 100%;
    height:100px;
    display:flex;
    flex-direction: row;
    border-bottom: 1px solid #EEEEEE;
    justify-content: flex-start;
    align-items: center;
    cursor:pointer;
    transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
}

.quote-row-wrapper:hover{
    background-color: rgba(0,0,0,0.04);
}

.quote-row-card{
    display: none;
    flex-direction: column;
}

.quote-table{
    border:1px solid #DDDDDD;
    border-radius: 4px;
    overflow: hidden;
    background-color: #FFFFFF;
}

.quote-table-row{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    min-height: 30px;
    height: 30px;
}

.quote-table-row:not(:last-child){
    border-bottom: 1px solid #DDDDDD;
}

.quote-table-row-left{
    width:40%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-left: 8px;
    border-right: 1px solid #DDDDDD;
    height:100%;
}

.quote-table-row-right{
    width:60%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-left: 8px;
    height:100%;
}

.quote-table-left-text{
    font-size: 12px;
    color:#222222;
    font-weight: bold;
    margin: 0;
    text-align: left;
}

.quote-table-right-text{
    font-size: 12px;
    color:#222222;
    margin: 0;
    text-align: left;
}

.billing-tables-wrapper{
    width: 100%;
    display: flex;
    flex-direction: row;
}

.delivery-information-table{
    flex:1;
}

.billing-information-table{
    flex:1;
}

.quote-line-wrapper{
    width: 100%;
    height:80px;
    display: flex;
    flex-direction: row;
}

.quote-line-wrapper:not(:last-child){
    border-bottom: 1px solid #DDDDDD;
}

.quote-line-wrapper-cell{
    height:100%;
    padding: 4px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.quote-line-wrapper-cell:not(:last-child){
    border-right:1px solid #DDDDDD;
}

.quote-line-image-wrapper{
    width: 100%;
    height:100%;
}

.quote-image{
    height:100%;
    width: 100%;
    border-radius: 4px;
    border:1px solid #EEEEEE;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
}

.quote-pennant{
    height:50%;
    width: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: absolute;
    left:0;
    top:0;
    z-index: 1;
}

.quote-line-value{
    width: 100%;
    font-size: 12px;
    color: #222222;
    margin: 0;
    text-align: center;
}

@media (max-width: 968px){
    .quote-row-wrapper{
        display: none;
    }

    .quote-row-card{
        display: flex;
    }

    .quote-wrapper{
        padding: 1px;
    }

    .billing-tables-wrapper{
        flex-direction: column;
    }
}
