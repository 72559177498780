.cart-header{
    width: 100%;
    margin-top: 25px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
}

.cart-header-grid{
    flex:1;
    display: flex;
    flex-direction: column;
    height:100%;
    padding-right: 12px;
}

.cart-header-grid-right{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
}

.cart-header-recap{
    width: 340px;
    max-width: 100%;
}

.card-header-bottom-left{
    position: absolute;
    left:0;
    bottom:0;
    width: calc(100% - 340px);
    padding-right: 12px;
}

@media (max-width: 768px) {
    .cart-header{
        flex-direction: column;
        padding-bottom: 72px;
    }
    .cart-header-grid-right{
        margin-top: 25px;
    }
    .card-header-bottom-left{
        width: 100%;
        padding-right: 0;
    }
    .cart-laius{
        display: none;
    }
}
