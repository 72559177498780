.header-wrapper{
    width: 100%;
}

.header-top-wrapper{
    height:150px;
    width: 100%;
    background-color: #0068B3;
}

.header-search-wrapper{
    height:50px;
    width: 100%;
    background-color: #FAFAFA;
}

.header-content-wrapper{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height:150px;
}

.header-center-wrapper{
    flex:1;
    padding-left: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.header-right-wrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
}

@media (max-width: 768px){
    .header-center-wrapper *{
        display: none !important;
    }
}
