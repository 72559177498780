.home-page-wrapper{
    width: 100%;
}

.carousel-image{
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
}
