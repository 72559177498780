.category-content-wrapper{
    width: 100%;
    display: flex;
    flex-direction: row;
}

.category-content-paper{
    width: 350px;
    margin-right: 25px;
}

.category-content-image{
    width:calc(100% - 250px - 25px);
    height:250px;
    background-repeat: no-repeat;
    background-size: cover;
}

@media (max-width: 768px){
    .category-content-wrapper{
        flex-direction: column-reverse;
    }

    .category-content-paper{
        width: 100%;
        margin-right: 0;
        margin-top: 25px;
    }

    .category-content-image{
        width:100%;
        height:150px !important;
    }
}
