html {
    height: 100vh;
    overflow: hidden;
    max-height: 100vh;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height:100vh;
    width: 100vw;
    padding: 0;
}

#root{
    height:100vh;
    width: 100vw;
    overflow-x: auto;
}

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Chrome/Safari/Opera */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently not supported by any browser */
}

* {
    box-sizing: border-box;
}

.centerer-wrapper{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-left: 10px;
    padding-right: 10px;
}

.centerer{
    width: 100%;
    max-width: 1160px;
}

.hidden-scrollbar{
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.hidden-scrollbar::-webkit-scrollbar{
    display: none;
}

.linkStyle {
    color:#1976d2;
    text-decoration: none;
}

.linkStyle:hover {
    cursor: pointer;
    text-decoration: underline;
}
