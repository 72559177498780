.about-page-content-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
}

@media (max-width: 768px) {
    .about-page-content-wrapper{
        flex-direction: column;
    }
    .btx-image{
        width: 100%;
        margin-bottom: 20px;
    }
    .btx-texts-wrapper{
        padding-left: 0 !important;
    }
}
