.search-bar-wrapper{
    width:100%;
    height:50px;
    border-bottom: 1px solid #CCCCCC;
    background-color: #FFFFFF;
}

.search-bar-fixed{
    position: fixed;
    left:0; top:0; right:0; z-index: 1200;
}

.search-bar-content-wrapper{
    width:100%;
    height:100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.search-bar-results-wrapper{
    width: 100%;
    position:absolute;
    left:0; right:0;
    top:50px;
    z-index: 250;
    pointer-events: none;
}

@media (min-width: 968px) {
    .search-bar-fixed{
        padding-right: 16px;
    }
}
