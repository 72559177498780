.menu-main-wrapper{
    width: 100%;
    position: relative;
}

.menu-top-bar{
    width: 100%;
    background-color: #0068B3;
    height: 54px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-left: 6px;
    padding-right: 6px;
}

.menu-top-bar-categories-wrapper{
    flex:1;
    height:100%;
    display: flex;
    flex-direction: row;
}

.menu-top-bar-hover-button{
    height: 100%;
    min-width: 54px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
    cursor: pointer;
    max-width: 150px;
    overflow: hidden;
}

.menu-top-bar-hover-button-label{
    text-align: center;
    color: #FFFFFF;
    margin: 0;
    transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
    font-size: 12px;
    text-transform: uppercase;
    font-weight: bold;
}

.menu-top-bar-hover-button-icon{
    transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
    color: #FFFFFF;
}

.menu-top-bar-hover-button:hover{
    background-color: #FFFFFF;
}

.menu-top-bar-hover-button:hover .menu-top-bar-hover-button-icon, .menu-top-bar-hover-button:hover .menu-top-bar-hover-button-label{
    color: #0068B3;
}

.menu-top-bar-hover-button-selected{
    background-color: #FFFFFF !important;
}

.menu-top-bar-hover-button-selected *{
    color:#0068B3 !important;
}

.menu-bottom-content{
    width: 100%;
    position: absolute;
    top:54px;
    left:0;
    z-index:150;
    height:calc(100vh - 320px);
    background-color: #FFFFFF;
    box-shadow: rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px;
    display: flex;
    flex-direction: row;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    overflow: hidden;
}

.menu-bottom-content-left-pane{
    width: 350px;
    height:100%;
    border-right: 1px solid #EFEFEF;
    background-color: #FFFFFF;
    overflow-y: auto;
}

.menu-bottom-content-right-pane{
    width: calc(100% - 350px);
    height:100%;
    background-color: #FAFAFA;
    overflow-y: auto;
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start
}

.menu-subcategory-button{
    width: 100%;
    height:46px;
    transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 8px;
}

.menu-subcategory-button:hover{
    background-color: #EFEFEF;
}

.menu-subcategory-label{
    flex:1;
    color:#222222;
    font-size: 14px;
    margin:0;
    text-align: left;
    padding-right: 8px;
}

.menu-subcategory-icon{
    font-size: 18px;
    color: #555555;
}

.menu-subcategory-button-selected{
    background-color: #EFEFEF !important;
}

.small-categories-button{
    display: none;
}

@media(max-width: 1199px){
    .menu-top-bar-categories-wrapper{
        display: none;
    }
    .small-categories-button{
        display: block;
    }
}

.mobile-menu-wrapper{
    position:fixed;
    left:0;
    top:0;
    bottom:0;
    right:0;
    z-index: 250;
    height:100vh;
    width: 100vw;
    pointer-events: none;
}

.mobile-menu-inner-wrapper{
    width: 100%;
    height:100%;
    position: relative;
}

.mobile-menu-backdrop{
    opacity:0;
    background-color: rgba(0,0,0,0.35);
    transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
    width: 100%;
    height:100%;
    position: absolute;
    left:0;
    top:0;
    bottom:0;
    right:0;
    z-index:300;
}

.mobile-menu-slider{
    width: 450px;
    max-width: calc(100% - 40px);
    height:100%;
    transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
    position: absolute;
    left:0;
    top:0;
    bottom:0;
    right:0;
    z-index: 310;
    background-color: #FFFFFF;
    transform: translateX(-110%);
}

.mobile-menu-wrapper-displayed{
    pointer-events: all;
}

.mobile-menu-wrapper-displayed .mobile-menu-backdrop{
    opacity: 1;
}

.mobile-menu-wrapper-displayed .mobile-menu-slider{
    transform: translateX(0);
}

.shadowed-header{
    box-shadow: rgb(0 0 0 / 20%) 0px 2px 1px -1px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px;
}

.mobile-menu-category, .mobile-menu-sub-category, .mobile-menu-under-sub-category{
  width: 100%;
}

.mobile-menu-link{
    transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
}

.mobile-menu-link:hover{
    background-color: #EFEFEF;
}

.toggler{
    height:40px;
    width: 40px;
    border-radius: 50%;
    transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.toggler:hover{
    background-color: #EFEFEF;
}

.toggler:active{
    /*transform: scale(0.95);*/
}

.toggler-opened{
    transform: rotate(90deg);
}

/*CAT*/

.mobile-menu-category-row{
    width:100%;
    height:44px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-right: 4px;
    border-bottom:1px solid #CCCCCC;
}

.mobile-menu-category-content-wrapper{
    width: 100%;
    padding-left: 12px;
    background-color: #FAFAFA;
}

.mobile-menu-category-content{
    width: 100%;
    border-left: 1px solid #EFEFEF;
    background-color: #FFFFFF;
}

/*SUBCAT*/

.mobile-menu-sub-category-row{
    width:100%;
    height:44px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-right: 4px;
    border-bottom:1px solid #EFEFEF;
}

.mobile-menu-sub-category-content-wrapper{
    width: 100%;
    padding-left: 12px;
    background-color: #FAFAFA;
    border-bottom: 1px solid #EFEFEF;
}

.mobile-menu-sub-category-content{
    width: 100%;
    border-left: 1px solid #EFEFEF;
    background-color: #FFFFFF;
}
