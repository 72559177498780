.admin-content-wrapper{
    width: 100%;
    display: flex;
    flex-direction: row;
}

.admin-right-content{
    flex:1;
    padding-left: 12px;
    min-height: 120px;
}

@media (max-width: 968px) {
    .admin-content-wrapper{
        flex-direction: column !important;
    }

    .admin-right-content{
        width: 100%;
        padding-left: 0;
        margin-top:12px
    }
}
