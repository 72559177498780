.cart-validate-coordinates-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
}

.cart-validate-coordinates-grid-elt{
    flex:1;
    display: flex;
    flex-direction: column;
}

.cart-validate-login-popup{
    width: 300px;
    height:250px;
    margin-top: 67px;
    padding: 12px;
    border-radius: 6px;
    background-color: #0068B3 !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 12px;
}

.cart-validate-recap-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
}

.cart-validate-recap{
    width: 340px;
    margin-top: 12px;
    margin-right: 12px;
}

@media (max-width: 768px){
    .cart-validate-coordinates-wrapper{
        flex-direction: column-reverse;
    }

    .cart-validate-login-popup{
        max-width: 100%;
        margin-top: 12px !important;
        margin-left: 0;
    }

    .cart-validate-coordinates-grid-elt{
        padding-right: 0;
    }

    .cart-validate-recap-wrapper{
        flex-direction: column;
    }

    .cart-validate-recap{
        max-width: 100%;
    }
}
