.my-account-content-wrapper{
    width: 100%;
    display: flex;
    flex-direction: row;
}

@media (max-width: 968px) {
    .my-account-content-wrapper{
        flex-direction: column !important;
    }

    .my-account-outlet-wrapper{
        padding-left: 0 !important;
        margin-top: 12px !important;
        width: 100%;
    }
}
